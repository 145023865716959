import useAddress from '~/models/Address';
import useCaregroup from '~/models/Caregroup';
import usePatientInfo from '~/models/PatientInfo';
import usePhoneNumber from '~/models/PhoneNumber';
import useUser from '~/models/User';
import useAuth from '~/stores/Auth';
import useNotification from '~/stores/Notification';
import { ClassName } from '~/typings/enums';
import updatedAuthUserHandler from './updatedAuthUserHandler';
import useCounterReading from '~/models/CounterReading';
import useTherapy from '~/models/Therapy';
import useBigRegistration from '~/models/BigRegistration';
import useContact from '~/models/Contact';
import Log from '../logging/Logger';
import useLocation from '~/models/Location';
import type { Model, ModelActions } from '~/typings/model';
import type {
    BroadcastedModelEventValue,
    BroadcastNotificationCreatedEventValue,
    EventHandlers,
    LogOutEventValue,
    NotificationEventValue,
} from './types';

const classNameToStoreMap: Record<ClassName, (() => ModelActions<Model, Model, any, any>) | null> = {
    [ClassName.Address]: useAddress,
    [ClassName.BigMention]: null,
    [ClassName.BigRegistration]: useBigRegistration,
    [ClassName.Caregroup]: useCaregroup,
    [ClassName.CaregroupModule]: null,
    [ClassName.CaregroupPreference]: null,
    [ClassName.Contact]: useContact,
    [ClassName.CounterReading]: useCounterReading,
    [ClassName.EmailVerification]: null,
    [ClassName.Media]: null,
    [ClassName.Notification]: null,
    [ClassName.NotificationTemplate]: null,
    [ClassName.PatientInfo]: usePatientInfo,
    [ClassName.PhoneNumber]: usePhoneNumber,
    [ClassName.TherapyRequest]: useTherapy,
    [ClassName.TrackingSystemBarcode]: null,
    [ClassName.TrackingSystemCaregroupFunction]: null,
    [ClassName.TrackingSystemCaregroupMaterial]: null,
    [ClassName.TrackingSystemCaregroupPartnerFunction]: null,
    [ClassName.TrackingSystemFilling]: null,
    [ClassName.TrackingSystemInventory]: null,
    [ClassName.TrackingSystemInvoice]: null,
    [ClassName.TrackingSystemLabel]: null,
    [ClassName.TrackingSystemLocation]: useLocation,
    [ClassName.TrackingSystemLocationChange]: null,
    [ClassName.TrackingSystemMaterial]: null,
    [ClassName.TrackingSystemOrder]: null,
    [ClassName.TrackingSystemOrderItem]: null,
    [ClassName.TrackingSystemPartner]: null,
    [ClassName.TrackingSystemStock]: null,
    [ClassName.TwoFactorCode]: null,
    [ClassName.User]: useUser,
};

const eventHandlers: EventHandlers = {
    UpdatedModelEvent(event: BroadcastedModelEventValue): void {
        const auth = useAuth();

        if (event.className === ClassName.User && event.uuid === auth.user?.uuid) {
            updatedAuthUserHandler();

            return;
        }

        const useStore = classNameToStoreMap[event.className];
        const store = useStore?.();

        if (!store?.models.has(event.uuid)) {
            return;
        }

        const currentlyAvailableFields = Object.keys(store.models.get(event.uuid));

        store.fetch(event.uuid, {
            fields: currentlyAvailableFields,
        });
    },
    DeletedModelEvent(event: BroadcastedModelEventValue): void {
        const useStore = classNameToStoreMap[event.className];
        useStore?.().models.delete(event.uuid);
    },
    BroadcastNotificationCreatedEvent(event: BroadcastNotificationCreatedEventValue): void {
        useNotification().addNotification({
            uuid: event.uuid,
            template: event.template,
            created_at: event.created_at,
        });
    },
    NotificationEvent(event: NotificationEventValue): void {
        const auth = useAuth();

        if (event.user_uuid === auth.user?.uuid) {
            useNotification().addNotification({
                uuid: event.uuid,
                template: event.template,
                created_at: event.created_at,
            });
        }
    },
    LogOutEvent(event: LogOutEventValue): void {
        Log.debug('[Events]: LogOut event received, logging out.');

        const auth = useAuth();

        if (auth.user?.uuid === event.uuid) {
            auth.invalidate();
        }
    },
};

export default eventHandlers;
